import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <div style={{
                margin: 0,
                padding: 0,
                height: '100vh',  // Changed from 100% to 100vh to make it occupy the full viewport height
                overflow: 'hidden',
                position: 'relative'
            }}>
                <iframe 
                    src="https://embed.ipfscdn.io/ipfs/bafybeicd3qfzelz4su7ng6n523virdsgobrc5pcbarhwqv3dj3drh645pi/?contract=0xB19507b6aB72BABe9BfB72262435E5B162E6F696&chain=%7B%22name%22%3A%22Ethereum+Mainnet%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2Fethereum.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22eth%22%2C%22chainId%22%3A1%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22ethereum%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fethereum%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=dac30f6245f0d5afcf5ed0a23a855c05&theme=dark&primaryColor=purple"
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                        zIndex: 1,
                        position: 'absolute',  // The iframe is made absolute to fit the container
                        top: 0,
                        left: 0
                    }}
                />
                <div style={{
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                    width: 50,
                    height: 50,
                    backgroundColor: 'black',
                    zIndex: 2
                }}></div>
            </div>
        </div>
    );
}

export default App;
